<template>
  <ul class="pa-0">
    <li>{{ name }}</li>
    <li>
      <span v-show="phoneOne">
        {{ phoneOne ?? phoneOne }}
      </span>
      <span v-show="phoneTwo">
        {{ ' | ' + phoneTwo }}
      </span>
    </li>
    <li>{{ email }}</li>
  </ul>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    phoneOne: {
      type: String,
      default: ''
    },
    phoneTwo: {
      type: String,
      default: ''
    },
    email: {
      type: String,
      default: ''
    },
  },
}
</script>
