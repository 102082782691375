<template>
  <v-container fluid>
    <FilterDrawer
      current-component="vendors-filter"
      @filter="filterVendors"
      @clear="clearFilter"
    />
    <Table :data="dataTable">
      <template v-slot:tbody="{ item }">
        <td>{{ item.company_name }}</td>
        <td>{{ item.trading_name }}</td>
        <td>
          <ContactInfo
            :name="item.trading_name"
            :phone-one="item?.contacts && item?.contacts.length ? item.contacts[0].primary_phone : ''"
            :email="item?.vendor_contact_email || ''"
          />
        </td>
        <td>{{ item.segment }}</td>
        <td class="text-right">
          {{ item.updated_at }}
        </td>
        <td class="text-center">
          <StatusChip :current-status="item.status" />
        </td>
      </template>
    </Table>
  </v-container>
</template>

<script>
import { contextMenu, massAction } from '@/main.js'
import Table from '@/components/data-table/Table'
import FilterDrawer from '@/components/FilterDrawer'
import StatusChip from '@/components/data-table/StatusChip'
import ContactInfo from '@/components/data-table/ContactInfo'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'

export default {
  components: {
    Table,
    FilterDrawer,
    StatusChip,
    ContactInfo,
  },
  mixins: [checkProfileRoutePermission],
  data: () => ({
    dataTable: {
      title: 'Fornecedores',
      new: 'vendors-new',
      edit: 'vendors-edit',
      context: 'vendors-context',
      massAction: 'vendors-mass-action',
      headerButtons: {
        filter: true,
        new: true,
      },
      items: [],
      isLoading: false,
      headers: [
        { text: 'Razão Social', value: 'company_name' },
        { text: 'Nome Fantasia', value: 'trading_name' },
        { text: 'Contato', value: 'vendor_contact', align: 'left' },
        { text: 'Segmento', value: 'segment' },
        { text: 'Atualizado em', value: 'updated_at', align: 'end' },
        { text: 'Status', value: 'status', align: 'center' },
      ],
    },
  }),
  mounted() {
    this.getVendors()

    contextMenu.$once('action', (type) => {
      if (type === 'vendors') {
        this.getVendors()
      }
    })
    massAction.$once('action', () => {
      this.getVendors()
    })
  },
  methods: {
    getVendors() {
      this.dataTable.items = []
      this.dataTable.isLoading = true

      this.$api
        .get('vendors')
        .then((res) => {
          this.dataTable.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    filterVendors(filter) {
      this.dataTable.items = []
      this.dataTable.isLoading = true

      this.$api
        .post('vendors/filter', filter)
        .then((res) => {
          this.dataTable.items = res.data.data
        })
        .catch(() => {
          this.dataTable.items = []
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    clearFilter() {
      this.getVendors()
    },
  },
}
</script>
